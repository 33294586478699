<template>
  <div>
    <!-- <h1>Home</h1> -->
    <!-- <router-link to="/login"> Login </router-link> -->
    <login></login>
    <!-- <register></register> -->
  </div>
</template>

<script>
import Login from "./Login";
// import Register from "./Register";

export default {
  name: "home",
  components: { Login },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  /* eslint-enable no-console */
};
</script>


