<template>
  <div>
    <body
      data-theme="default"
      data-layout="fluid"
      data-sidebar-position="left"
      data-sidebar-behavior="sticky"
    >
      <div class="main d-flex justify-content-center w-100">
        <main class="content d-flex p-0">
          <div class="container d-flex flex-column">
            <div class="row h-100">
              <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">
                  <div class="card">
                    <div class="card-body">
                      <div class="m-sm-4">
                        <div class="text-center">
                          <h1 class="h2">Welcome back!</h1>
                          <!-- <p class="lead"> Please login </p> -->
                        </div>

                        <form class="login" @submit.prevent="login">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              required
                              v-model="email"
                              type="text"
                              class="form-control form-control-lg"
                              placeholder="Name"
                            />
                          </div>
                          <div class="form-group">
                            <label>Password</label>
                            <input
                              required
                              v-model="password"
                              type="password"
                              class="form-control form-control-lg"
                              placeholder="Password"
                            />
                            <small>
                              <a href="pages-reset-password.html"
                                >Forgot your password?</a
                              >
                            </small>
                          </div>
                          <div class="text-center mt-3">
                            <button
                              type="submit"
                              class="btn btn-primary btn-lg col-12"
                            >
                              Login
                            </button>
                          </div>

                          <div class="mt-3">
                            <label class="text-small">Need an account?</label>
                            <!-- <a href="pages-reset-password.html"> Register</a> -->
                            <router-link to="/register"> Register </router-link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </body>
  </div>
</template>

<script>
    /* eslint-disable no-console */

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login: async function () {
      console.log("login function called", this.email, this.password);
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, user_password: password })
        .then(() => {
          console.log("then about to have router pushed");
          this.$router.push("/overview");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>