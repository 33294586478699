import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import http from './http-common'

// // this stuff allows for refresh and reload
// const token = localStorage.getItem('token')
// if (token) {
//     axios.defaults.headers.common['Authorization'] = token
//     http.defaults.headers.common['Authorization'] = token
// }
 /* eslint-disable no-console */

// Vuex store
Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        status: '',
        token: '',
        user: null,
        preferences: '',
        http: http,
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, data) {
            state.status = 'success'
            state.token = data.token
            state.user = data.user
            state.preferences = data.preferences
            state.http = http;
            state.http.defaults.headers.common['Authorization'] = "Bearer " + data.token;
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.user = null
            state.preferences = null
            state.http = null
        },
        update_preferences(state, preferences) {
            state.preferences = preferences
        }
    },
    actions: {
        login({
            commit
        }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                http({
                        url: '/login-user',
                        data: user,
                        method: 'POST'
                    })
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        const preferences = resp.data.preferences

                        commit('auth_success', {
                            token,
                            user,
                            preferences,
                        })

                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.clear();
                        reject(err)
                    })
            })
        },
        register({
            commit
        }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                http({
                        url: '/register-user',
                        data: user,
                        method: 'POST'
                    })
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        const preferences = resp.data.preferences

                        commit('auth_success', {
                            token,
                            user,
                            preferences,
                        })

                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        localStorage.clear();
                        reject(err)
                    })
            })
        },
        logout({
            commit
        }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.clear();
                resolve()
            })
        },
        updatePreferences({
            commit
        }, preferences) {
            return new Promise((resolve, reject) => {
                http
                    .post("api/" + this.state.user + "/update-preferences", preferences)
                    .then((response) => {
                        if (response.data == "Success") { //** this might be superfluous
                            commit('update_preferences', preferences) // then commit to local state
                        }
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    }); 
                })
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
})
 /* eslint-enable no-console */