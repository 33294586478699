<template>
  <div>
    <line-chart 
      :chartData="dataCollection"
      :options="options"
      :width="1200"
      :height="800"
    ></line-chart>
  </div>
</template>
 
<script>
/* eslint-disable no-console */

import LineChart from "./ChartObject";

export default {
  name: "chart",
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      maxPoints: 400,
      colours: [
        "f72585",
        "b5179e",
        "7209b7",
        "560bad",
        "480ca8",
        "3a0ca3",
        "3f37c9",
        "4361ee",
        "4895ef",
        "4cc9f0",
      ],
      dataCollection: null,
      options: null,
    };
  },
  methods: {
    fillData() {
      this.dataCollection = {
        labels: this.filterDataBasedOnMaxPoints(this.chartData.all_dates),
        datasets: this.datasets(),
      };

      this.options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (label) {
                  return "$" + Intl.NumberFormat().format(label);
                },
              },
              gridLines: {
                display: true,
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                maxTicksLimit: 20,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        // hover: {
        // 	mode: 'nearest',
        // 	intersect: false},
        tooltips: {
          // enabled: true,
          // mode: "point"
          mode: "index",
          intersect: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        padding: {},
        legend: {
          display: true,
          // labels: {
          // 	fontColor: 'rgb(255, 99, 132)'
          // }
        },
        plugins: {
          filler: {
            propagate: true,
          },
        },
      };
    },
    filterDataBasedOnMaxPoints(data) {
      // Need to re-do this, not working
      // Rethink based on we have 1700 points, need to get to 200,
      // Need to remove X per Y.
      // need to find fractions of each value.
      let length = data.length;
      let ratio = Math.trunc(data.length / this.maxPoints);
      
      let newDataset = Object.assign([], data); // To prevent mutation of data

      let reversedArray = newDataset.reverse();
      let newArray = [];
      if (ratio < 2) {
        return data;
      } else {
        for (var i = 0; i <= length; i += ratio) {
          // shrink ar
          newArray.push(reversedArray[i]);
        }
        return newArray.reverse();
      }
    },
    datasets() {
      let datasets = [];
      const emptyDataset = {
        label: "",
        data: [],
        backgroundColor: "",
        borderColor: "",
        borderWidth: 0.1,
        fill: true,
      };

      let colours = [
        "f72585",
        "b5179e",
        "7209b7",
        "560bad",
        "480ca8",
        "3a0ca3",
        "3f37c9",
        "4361ee",
        "4895ef",
        "4cc9f0",
      ];

      let chartData = Object.assign({}, this.chartData);

      // Loop over unique tickers in
      chartData.unique_tickers.forEach((ticker, i) => {
        // console.log(ticker, i)
        let newDataset = Object.assign({}, emptyDataset);
        newDataset.label = ticker;

        // Filter data
        let filtered_data = this.filterDataBasedOnMaxPoints(chartData.all_daily_asset_values[ticker]);
        // let filtered_data = [0,12,444,352145,123451342,134,134,134,134,134,5,4,3,3,5,41,1];

        // Modify data to replace 0 with null
        let newData = filtered_data.map(
          (dataPoint) => {
            if (dataPoint === 0) {
              return null;
            } else {
              return dataPoint;
            }
          }
        );

        // Assign data
        newDataset.data = newData;

        // Assign colours
        if (i < colours.length) {
          // console.log(i, colours.length);
          newDataset.backgroundColor = "#" + colours[i];
          newDataset.borderColor = "#" + colours[i];
        } else {
          newDataset.backgroundColor = "#" + colours[colours.length - 1];
          newDataset.borderColor = "#" + colours[colours.length - 1];
        }
        
        // console.log(datasets);
        datasets.push(newDataset);
      });
      return datasets;
    },
  },
  created() {
    this.fillData();
},
watcher: {
  chartData: ()=> {
    this.fillData();
  }
}

  /* eslint-enable no-console */
};
</script>
 
<style>
.center {
  margin: auto;
  width: 100%;
  padding: 10px;
}
</style>