<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable no-console */


export default {
    name: "app",
    computed : {
    },
    methods: {
        logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
            this.$router.push('/login')
        })
        }
    },
    created: function () {
        this.$store.state.http.interceptors.response.use(undefined, function (err) {
            return new Promise(function () {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                this.$store.dispatch('logout')
                }
                throw err;
            });
        });

        // reinject token when page reloads 
        this.$store.state.http.defaults.headers.common['Authorization'] = "Bearer " + this.$store.state.token;
    },
    beforeCreate: function () {
        if (this.$store.state.preferences.theme == 'light') {
            import ('./scss/light.scss')
        } else {
            // Dark is already loaded as default in main.js
        }
    }
};
/* eslint-enable no-console */

</script>
 
<style>
</style>