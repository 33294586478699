<template>
  <div>
    <div class="wrapper">
      <nav id="sidebar" class="sidebar">
        <div class="sidebar-content js-simplebar">
          <!-- Logo -->
          <a class="sidebar-brand" href="/overview">
            <span class="align-middle me-3">FinPlatform</span>
          </a>

          <!-- Sidebar navigation -->
          <ul class="sidebar-nav">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/overview"
                >Overview</router-link
              >
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/fin-assets"
                >Financial assets</router-link
              >
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/add-transaction"
                >Add transaction</router-link
              >
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/add-custom-prices"
                >Add prices for custom assets</router-link
              >
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/taxes"
                >Taxes</router-link
              >
            </li>
          </ul>
        </div>
      </nav>

      <div class="main">
        <!-- Top navbar -->
        
        <nav class="navbar navbar-expand navbar-light navbar-bg">
          <a v-on:click="toggleNav" class="sidebar-toggle">
            <i class="hamburger align-self-center"></i>
          </a>
          
          <div class="navbar-collapse collapse">
            <ul class="navbar-nav navbar-align">
              <li class="nav-item dropdown">
                <b-dropdown
                  id="dropdown-1"
                  text="Account"
                  class="m-md-2"
                  variant="light"
                >
                  <b-dropdown-item to="/preferences">Preferences</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
                  <!-- <b-dropdown-item active>Active action</b-dropdown-item>
                  <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
                </b-dropdown>
              </li>
            </ul>
          </div>
        </nav>

        <!-- Page data -->
        <router-view :stockPortfolio="stockPortfolio"></router-view>
        <footer class="footer">
          <div class="container-fluid">
            <div class="row text-muted">
              <div class="col-6 text-start">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a class="text-muted" href="#">Support</a>
                  </li>
                  <li class="list-inline-item">
                    <a class="text-muted" href="#">Help Center</a>
                  </li>
                  <li class="list-inline-item">
                    <a class="text-muted" href="#">Privacy</a>
                  </li>
                  <li class="list-inline-item">
                    <a class="text-muted" href="#">Terms of Service</a>
                  </li>
                </ul>
              </div>
              <div class="col-6 text-end">
                <p class="mb-0">
                  &copy; 2021 -
                  <a href="index.html" class="text-muted">FinPlatform</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  /* eslint-disable no-console */
  name: "dashboard",
  data() {
    return {
      stockPortfolio: null,
      cryptoPortfolio: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    dataLoad() {
      // function that loads data/processing in correct order
      this.getPortfolio() // Retrieve trades promise
        .then((result) => {
          this.stockPortfolio = JSON.parse(result.data); // Assign to local variable
        //   return this.getCryptoPortfolio() // Return next promise
        // }).then((result)=> {
        //   this.cryptoPortfolio = JSON.parse(result.data);
      });
    },
    getPortfolio() {
      // console.log("getStockPortfolio called");
      console.log(this.$store.state.http.defaults.headers);
      return this.$store.state.http.get("/api/" + this.user + "/get-stock-portfolio");
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    toggleNav() {
      if (document.getElementById("sidebar").className == "sidebar") {
        document.getElementById("sidebar").classList.add("collapsed");
      } else {
        document.getElementById("sidebar").className = "sidebar";
      }
    },
    internalRateOfReturn(values, dates, guess) {
      // Credits: algorithm inspired by https://gist.github.com/ghalimi/4591338

      // Calculates the resulting amount
      var irrResult = function (values, dates, rate) {
        var r = rate + 1;
        var result = values[0];
        for (var i = 1; i < values.length; i++) {
          result += values[i] / Math.pow(r, (dates[i]) / 365 );
        }
        return result;
      };

      // Calculates the first derivation
      var irrResultDeriv = function (values, dates, rate) {
        var r = rate + 1;
        var result = 0;
        for (var i = 1; i < values.length; i++) {
          var frac = (dates[i]) / 365;
          result -= (frac * values[i]) / Math.pow(r, frac + 1);
        }
        return result;
      };

      // Initialize dates and check that values contains at least one positive value and one negative value
      // var dates = [];
      var positive = false;
      var negative = false;
      for (var i = 0; i < values.length; i++) {
        // dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
        if (values[i] > 0) positive = true;
        if (values[i] < 0) negative = true;
      }

      // Return error if values does not contain at least one positive value and one negative value
      if (!positive || !negative) return "#NUM!";

      // Initialize guess and resultRate
      guess = typeof guess === "undefined" ? 0.1 : guess;
      var resultRate = guess;

      // Set maximum epsilon for end of iteration
      var epsMax = 1e-10;

      // Set maximum number of iterations
      var iterMax = 50;

      // Implement Newton's method
      var newRate, epsRate, resultValue;
      var iteration = 0;
      var contLoop = true;
      do {
        resultValue = irrResult(values, dates, resultRate);
        newRate =
          resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
        epsRate = Math.abs(newRate - resultRate);
        resultRate = newRate;
        contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
      } while (contLoop && ++iteration < iterMax);

      if (contLoop) return "#NUM!";

      // Return internal rate of return
      return resultRate;
    },
    generateIRR(){
      // generate array of date indexes, with 0 being date of first transaction and t being today
      let start_date = new Date(this.stockPortfolio.transactions[0].transaction_date);
      // console.log('start date', start_date);
      let array_of_dates = this.stockPortfolio.transactions.map(transaction => {
        let current_date = new Date(transaction.transaction_date);
        let diff = (current_date-start_date)/(1000 * 3600 * 24);
        return diff
      });

      array_of_dates.push((new Date()-start_date)/(1000 * 3600 * 24)); // Add todays date

      // array of cash flows 
      // console.log(this.stockPortfolio.transactions);
      let array_of_cashflows = this.stockPortfolio.transactions.map(transaction => {
        // console.log(transaction);
        if (transaction.transaction_type == "BUY") {
          // console.log('calculated value', transaction.price * -transaction.quantity)
          return transaction.price * -transaction.quantity
        } else if (transaction.transaction_type == "SELL") {
          // console.log('calculated value', transaction.price * transaction.quantity)
          return transaction.price * transaction.quantity
        }
      });

      // get current value of portfolio
      let current_value_of_portfolio = 0.0;

      for (let item in this.stockPortfolio.all_daily_asset_values) {
        let array = this.stockPortfolio.all_daily_asset_values[item];
        current_value_of_portfolio += array[array.length-1];
      }

      array_of_cashflows.push(current_value_of_portfolio);// add current portfolio value

      // console.log("array of cash flows", array_of_cashflows);
      // console.log("sum of cash flows", array_of_cashflows.reduce((a, b) => a + b, 0));

      // console.log('testIRR', this.internalRateOfReturn(array_of_cashflows,array_of_dates,.1));
      // console.log('testIRR', this.internalRateOfReturn([-200000,250000],[0,365],.1));
      // console.log('testIRR', this.internalRateOfReturn([-2604802.3789999997, 2550445.6855],[0, 70],.1));
      // console.log('testIRR', this.internalRateOfReturn([-2880833.363, -100038.405, 34992, 2550445.6855],[0, 1, 15, 80.00967668981481],.1));
    },
  },
  mounted() {
    this.dataLoad();
  },
  /* eslint-enable no-console */
};
</script>


