<template>
  <div>
    <!-- Content section -->
    <main class="content">
      <div class="container-fluid p-0">
        <div class="row mb-2 mb-xl-3">
          <div class="col-auto d-none d-sm-block">
            <h3>Overview</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 d-flex">
            <div class="card flex-fill w-100">
              <div class="card-header">
                <h5 class="card-title mb-0">Portfolio Value</h5>
              </div>
              <div class="card-body d-flex w-100">
                <div class="align-self-center chart chart-lg">
                  <charts v-if="stockPortfolio != null" :chartData="stockPortfolio"></charts>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="card flex-fill w-100">
              <div class="card-header">
                <h5 class="card-title mb-0">Balance Sheet</h5>
              </div>
                <balance-sheet :balanceSheetData="balanceSheetData"></balance-sheet>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div>
    </div>
  </div>
</template>

<script>
import Charts from "./Charts";
import BalanceSheet from "./BalanceSheet";

export default {
  components: { Charts, BalanceSheet},
  props: {
    stockPortfolio: {
      type: Object,
      default: null
    },
  },
  name: "overview",
  data() {
    return {
      prices: [],
      cost: {},
      portfolioTableData: 0,
      jwt: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    balanceSheetData() {
      if (this.stockPortfolio !== null) {
        let uniqueStocks = this.stockPortfolio.all_daily_asset_values;
        let balanceSheetData = [];
        for (var ticker in uniqueStocks) {
          let value = uniqueStocks[ticker][uniqueStocks[ticker].length - 1];
          balanceSheetData.push({ ticker, value });
        }
        return balanceSheetData;
      }
      return []
    },
  },
  methods: {
    /* eslint-disable no-console */
    currentCostBase: (array) =>
      array.reduce((acc, curr) => acc + curr.quantity * curr.price, 0),
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  mounted() {

  },
  /* eslint-enable no-console */
};
    

</script>
 
<style>
/* .list {
  text-align: left;
  max-width: 450px;
  margin: auto;
} */
</style>


