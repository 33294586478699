<template>
    <div>
        <h4>Register</h4>
        <form @submit.prevent="register">
        <label for="firstName">First name</label>
        <div>
            <input id="firstName" type="text" v-model="firstName" required autofocus />
        </div>
        <label for="lastName">Last name</label>
        <div>
            <input id="lastName" type="text" v-model="lastName" required autofocus />
        </div>

        <label for="email">E-Mail Address</label>
        <div>
            <input id="email" type="email" v-model="email" required />
        </div>

        <label for="password">Password</label>
        <div>
            <input id="password" type="password" v-model="password" required />
        </div>

        <label for="password-confirm">Confirm Password</label>
        <div>
            <input
            id="password-confirm"
            type="password"
            v-model="password_confirmation"
            required
            />
        </div>

        <div>
            <button type="submit">Register</button>
        </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: "register",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password_confirmation: "",
      is_admin: null,
    };
  },
  methods: {
    register: function () {
      let data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        user_password: this.password,
      };
      if (this.password !== this.password_confirmation) {
        alert("Passwords do not match")
      } else {
      this.$store
        .dispatch("register", data)
        .then(() => this.$router.push("/overview"))
        .catch((err) => console.log(err));
      }
    },
  },
};
/* eslint-enable no-console */
</script>