<template>
  <div>
    <div class="submitform">
      <h1>Add transactions</h1>
      <div class="form-group">
        <label>Security type</label>
        <select
          v-model="security_type"
          required
          class="form-control form-control-lg"
          @click="resetTrade()"
        >
          <option>share</option>
          <option>crypto</option>
          <option>custom</option>
        </select>
      </div>
      <div v-if="security_type == 'share' || security_type == 'crypto'">
        <div v-if="security_type == 'share'"  class="form-group">
          <label>Security location</label>
          <select
            v-model="security_location"
            required
            class="form-control form-control-lg"
          >
            <option value="AU">Australia</option>
            <option value="US">United States of America</option>
          </select>
        </div>
        <label>Search for security</label>
        <b-form-input v-model="stockQuery" placeholder="Search"></b-form-input>
        <ul id="stockSearch">
          <li v-for="(result, index) in queryResult" :key="index">
            <a class="dropdown-item" role="menuitem" @click="setAsset(index)">
              <!-- {{ result.exch_code }}: -->
              {{ result.security_name || result.id }}
            </a>
          </li>
        </ul>
        <br />
        <div v-if="!submitted">
          <div class="form-group">
            <label for="date">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="trades.name"
              name="name"
              disabled
            />
          </div>
          <div class="form-group">
            <label for="ticker">Ticker</label>
            <input
              type="text"
              class="form-control"
              id="ticker"
              required
              v-model="trades.ticker"
              name="ticker"
              disabled
            />
          </div>
          <div v-if="security_type == 'share'" class="form-group">
            <label for="currency">Currency</label>
            <input
              type="text"
              class="form-control"
              id="currency"
              required
              v-model="trades.currency"
              name="currency"
              disabled
            />
          </div>
          <div v-if="security_type == 'crypto'" class="form-group">
            <label for="currency">Currency</label>
            <select
              type="text"
              class="form-control"
              id="currency"
              required
              v-model="trades.currency"
              name="currency"
            >
              <option>AUD</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>
          <div class="form-group">
            <label for="date">Date</label>
            <input
              type="text"
              class="form-control"
              id="date"
              required
              v-model="trades.date"
              name="date"
            />
          </div>

          <div class="form-group">
            <label for="quantity">Quantity</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="quantity"
              required
              v-model="trades.quantity"
              name="quantity"
            />
          </div>
          <div class="form-group">
            <label for="price">Price</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="price"
              required
              v-model="trades.price"
              name="price"
            />
          </div>
          <div class="form-group">
            <label for="type">Transaction type</label>
            <br />
            <label>
              <input
                type="radio"
                name="type"
                id="type"
                value="BUY"
                required
                v-model="trades.type"
              />
              BUY </label
            ><br />

            <label>
              <input
                type="radio"
                name="type"
                id="type"
                value="SELL"
                v-model="trades.type"
              />
              SELL </label
            ><br />
          </div>
          <div class="form-group">
            <label for="brokerage">Brokerage</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="brokerage"
              required
              v-model="trades.brokerage"
              name="brokerage"
            />
          </div>

          <br />
          <button v-on:click="postTrade" class="btn btn-success">Submit</button>
        </div>

        <div v-else>
          <h4>You submitted successfully!</h4>
          <button class="btn btn-success" v-on:click="newTrade">Add</button>
        </div>
      </div>
      <div v-if="security_type == 'custom'">
        <label>Search for security</label>
        <b-form-input v-model="stockQuery" placeholder="Search"></b-form-input>
        <ul id="stockSearch">
          <li v-for="(result, index) in queryResult" :key="index">
            <a class="dropdown-item" role="menuitem" @click="setAsset(index)">
              <!-- {{ result.exch_code }}: -->
              {{ result.asset_name }}
            </a>
          </li>
        </ul>
        <br />
        <div v-if="!submitted">
          <div class="form-group">
            <label for="date">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="trades.name"
              name="name"
            />
          </div>
          <div class="form-group">
            <label for="currency">Currency</label>
            <select
              type="text"
              class="form-control"
              id="currency"
              required
              v-model="trades.currency"
              name="currency"
            >
              <option>AUD</option>
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>
          <div class="form-group">
            <label for="date">Date</label>
            <input
              type="text"
              class="form-control"
              id="date"
              required
              v-model="trades.date"
              name="date"
            />
          </div>

          <div class="form-group">
            <label for="quantity">Quantity</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="quantity"
              required
              v-model="trades.quantity"
              name="quantity"
            />
          </div>
          <div class="form-group">
            <label for="price">Price</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="price"
              required
              v-model="trades.price"
              name="price"
            />
          </div>
          <div class="form-group">
            <label for="type">Transaction type</label>
            <br />
            <label>
              <input
                type="radio"
                name="type"
                id="type"
                value="BUY"
                required
                v-model="trades.type"
              />
              BUY </label
            ><br />

            <label>
              <input
                type="radio"
                name="type"
                id="type"
                value="SELL"
                v-model="trades.type"
              />
              SELL </label
            ><br />
          </div>
          <div class="form-group">
            <label for="brokerage">Brokerage</label>
            <input
              type="number"
              step="0.0000001"
              class="form-control"
              id="brokerage"
              required
              v-model="trades.brokerage"
              name="brokerage"
            />
          </div>

          <br />
          <button v-on:click="postCustom" class="btn btn-success">
            Submit
          </button>
        </div>

        <div v-else>
          <h4>You submitted successfully!</h4>
          <button class="btn btn-success" v-on:click="newTrade">Add</button>
        </div>
      </div>
      <!-- <div>
        <textarea v-model="csvData"> </textarea>
        <br />
        <button @click="parseCSV">Parse CSV</button>
        <button @click="addBulkTransactions">addBulkTransactions</button>
        <br />
        {{ parsedCSVData }}
        <br />
      </div> -->
    </div>
  </div>
</template>
 
<script>
// import http from "../http-common";

export default {
  name: "add-transactions",
  data() {
    return {
      stockQuery: null,
      queryResult: null,
      trades: {
        date: "",
        security_id: "",
        ticker: "",
        quantity: 0,
        price: 0.0,
        type: "",
        brokerage: 0.0,
        currency: "",
        active: false,
      },
      security_type: "",
      security_location: "",
      submitted: false,
      csvData: "",
      parsedCSVData: null,
      queryData: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.user;
    },
  },
  methods: {
    /* eslint-disable no-console */
    postTrade() {
      // Check if userId is present
      if (this.userId !== null) {
        var data = {
          user_id: this.userId,
          transaction_date: this.trades.date,
          security_id: this.trades.security_id,
          quantity: parseFloat(this.trades.quantity),
          price: parseFloat(this.trades.price),
          transaction_type: this.trades.type,
          brokerage: parseFloat(this.trades.brokerage),
          currency: this.trades.currency,
          security_type: this.security_type,
        };

        this.$store.state.http
          .post("/api/" + this.userId + "/post-fin-transaction", data)
          .then((response) => {
            // this.trade.userid = response.data.userid;
            console.log("trade data pushed from frontend", response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        this.submitted = true;
        this.resetTrade();
      }
    },
    postCustom() {
      // Check if userId is present
      if (this.userId !== null) {
        var data = {
          user_id: this.userId,
          transaction_date: this.trades.date,
          security_id: this.trades.name,
          quantity: parseFloat(this.trades.quantity),
          price: parseFloat(this.trades.price),
          transaction_type: this.trades.type,
          brokerage: parseFloat(this.trades.brokerage),
          currency: this.trades.currency,
          security_type: this.security_type,
        };

        this.$store.state.http
          .post("/api/" + this.userId + "/post-custom-transaction", data)
          .then((response) => {
            // this.trade.userid = response.data.userid;
            console.log("trade data pushed from frontend", response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        this.submitted = true;
        this.resetTrade();
      }
    },
    newTrade() {
      this.submitted = false;
      this.trades = {};
    },
    queryStockDatabase() {
      this.$store.state.http
        .get(
          "/api/" +
            this.userId +
            "/security-validation/" +
            this.security_type +
            "/" +
            this.security_location +
            "/" +
            this.stockQuery
        )
        .then((response) => {
          this.queryResult = JSON.parse(response.data);
        });
    },
    setAsset(index) {
      let queryData = this.queryResult[index];
      if (this.security_type == "share") {
        this.setStock(queryData);
      }
      if (this.security_type == "crypto") {
        this.setCrypto(queryData);
      }
      if (this.security_type == "custom") {
        this.setCustom(queryData);
      }
      this.queryResult = undefined;
      this.stockQuery = undefined;
      this.security_location = undefined;
    },
    setCrypto(queryData) {
      this.trades.ticker = queryData.symbol;
      this.trades.name = queryData.name;
      this.trades.security_id = queryData.id;
    },
    setStock(queryData) {
      this.trades.ticker = queryData.ticker;
      this.trades.name = queryData.security_name;
      this.trades.security_id = queryData.figi;
      if (queryData.exch_code == "US") {
        this.trades.currency = "USD";
      } else if (queryData.exch_code == "AU") {
        this.trades.currency = "AUD";
      }
    },
    setCustom(queryData) {
      this.trades.name = queryData.asset_name;
      this.trades.currency = "USD";
    },
    resetTrade() {
      this.trades = {
        date: "",
        security_id: "",
        ticker: "",
        quantity: 0,
        price: 0.0,
        type: "",
        brokerage: 0.0,
        currency: "",
        active: false,
      };
      this.queryResult = undefined;
      this.stockQuery = undefined;
      this.security_location = undefined;
    },
    parseCSV() {
      const parse = require("csv-parse/lib/sync");

      const records = parse(this.csvData, {
        columns: true,
        skip_empty_lines: true,
      });
      this.parsedCSVData = records;
    },
    addBulkTransactions() {
      console.log(typeof this.parsedCSVData);
      for (let i in this.parsedCSVData) {
        console.log(trade);
        console.log();
        let trade = this.parsedCSVData[i];
        let dataToSend = {
          user_id: this.userId,
          transaction_date: trade.Date.split("/").reverse().join("-"),
          ticker: trade.Code,
          quantity: parseFloat(Math.abs(trade.Quantity)),
          price: parseFloat(trade.Price),
          transaction_type: trade.Type.toUpperCase(),
          brokerage: parseFloat(trade.Brokerage),
          currency: trade.currency,
          security_type: trade.security_type,
        };
        console.log(dataToSend);
        this.$store.state.http.post(
          "/api/" + this.userId + "/post-fin-transaction",
          dataToSend
        );
      }
    },
    /* eslint-enable no-console */
  },
  watch: {
    stockQuery: function () {
      this.queryStockDatabase();
    },
  },
};
</script>
 
<style>
.submitform {
  max-width: 300px;
  margin: auto;
}

#stockSearch {
  /* Remove default list styling */
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

#aa li a {
  border: 1px solid #ddd; /* Add a border to all links */
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6; /* Grey background color */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-size: 18px; /* Increase the font-size */
  color: black; /* Add a black text color */
  display: block; /* Make it into a block element to fill the whole list */
}

#aa li a:hover:not(.header) {
  background-color: #eee; /* Add a hover effect to all links, except for headers */
}
</style>