<template>
  <div>
    <!-- Content section -->
    <main class="content">
      <form class="preferences" @submit.prevent="updatePreferences">
        <div class="form-group">
          <label>Time Zone</label>
          <input
            required
            v-model="time_zone"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
        <div class="form-group">
          <label>Theme</label>
          <select v-model="theme" required class="form-control form-control-lg">
            <option>dark</option>
            <option>light</option>
          </select>
        </div>
        <div class="form-group">
          <label>Home currency</label>
          <select v-model="home_currency" required class="form-control form-control-lg">
            <option>AUD</option>
            <option>USD</option>
            <option>EUR</option>
          </select>
        </div>
        <br />
        <div class="text-left mt-3">
          <button
            :disabled="isDisabled()"
            type="submit"
            class="btn btn-primary"
          >
            Update preferences
          </button>
        </div>
      </form>
    </main>
    <div></div>
  </div>
</template>

<script>
export default {
  /* eslint-disable no-console */
  props: {},
  name: "preferences",
  data() {
    return {
      time_zone: null,
      theme: null,
      home_currency: null,
    };
  },
  computed: {
    preferences(){
      return this.$store.state.preferences;
    }
  },
  methods: {
    getPreferences() {
      this.time_zone = this.preferences.time_zone;
      this.theme = this.preferences.theme;
      this.home_currency = this.preferences.home_currency;
    },
    updatePreferences() {
      let preferences = {
        user_id: this.$store.state.user,
        time_zone: this.time_zone,
        theme: this.theme,
        home_currency: this.home_currency,
      };
      this.$store
        .dispatch("updatePreferences", preferences)
        .then(() => {
          window.location.reload() // javascript reload the page
          // console.log("then about to have router pushed");
          // this.$router.push("/overview");
        })
        .catch((err) => console.log(err));
      
      
    },
    isDisabled() {
      if (
        this.time_zone === this.preferences.time_zone &&
        this.theme === this.preferences.theme &&
        this.home_currency === this.preferences.home_currency
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getPreferences();
  },
  /* eslint-enable no-console */
};
</script>