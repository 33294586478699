<template>
  <div>
    CUSTOM ASSETS
    <form class="preferences" @submit.prevent="addPrice">
        <label>Search for security</label>
        <b-form-input v-model="stockQuery" placeholder="Search"></b-form-input>
        <ul id="stockSearch">
          <li v-for="(result, index) in queryResult" :key="index">
            <a class="dropdown-item" role="menuitem" @click="setCustom(index)">
              {{ result.asset_name }}
            </a>
          </li>
        </ul>
        <div class="form-group">
          <label>Asset name</label>
          <input
            required
            v-model="asset_name"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
        <div class="form-group">
          <label>Date</label>
          <input
            required
            v-model="date"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
        <div class="form-group">
          <label>Price</label>
          <input
            required
            v-model="price"
            type="text"
            class="form-control form-control-lg"
          />
        </div>
        <br />
        <div class="text-left mt-3">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Add price
          </button>
        </div>
      </form>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: "custom",
  data() {
    return {
      security_type: "custom",
      security_location: "Au",
      stockQuery: null,
      queryResult: null,
      asset_name: null,
      asset_id: null,
      date: null,
      price: null,
    }
  },
  props: {
    cryptoPortfolio: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userId() {
      return this.$store.state.user;
    },
  },
  methods: {
    queryCustomAssets() {
      this.$store.state.http
        .get(
          "/api/" +
            this.userId +
            "/security-validation/" +
            this.security_type +
            "/" +
            this.security_location +
            "/" +
            this.stockQuery
        )
        .then((response) => {
          this.queryResult = JSON.parse(response.data);
        });
    },
    setCustom(index) {
      let queryData = this.queryResult[index];
      this.asset_name = queryData.asset_name;
      this.asset_id = queryData.asset_id;
    },
    addPrice() {
      // Check if userId is present
      if (this.userId !== null) {
        var data = {
          p_date: this.date,
          asset_id: this.asset_id,
          price: parseFloat(this.price),
        };

        this.$store.state.http
          .post("/api/" + this.userId + "/post-custom-price", data)
          .then((response) => {
            // this.trade.userid = response.data.userid;
            console.log("trade data pushed from frontend", response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        this.submitted = true;
        this.resetPrice();
      }
    },
    resetPrice(){
      this.stockQuery = null;
      this.queryResult = null;
      this.asset_name = null;
      this.asset_id = null;
      this.date = null;
      this.price = null;
    }
  },
  watch: {
  stockQuery: function () {
    this.queryCustomAssets();
  },
  },
  
};
/* eslint-enable no-console */
</script>
 
<style>
</style>