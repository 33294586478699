<script>
/* eslint-disable no-console */

import {Line} from "vue-chartjs";

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watcher: {
    chartData: ()=>{
      this.renderChart(this.chartData, this.options);
    }
  }
  /* eslint-enable no-console */
};
</script>