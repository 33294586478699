<template>
  <div>
    <b-table
      id="balance-sheet-table"
      :items="balanceSheetData"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
      striped
    >
      <template #head(ticker)> Item </template>
      <template #head(value)> Value ($) </template>
      <template #cell(value)="data">
        {{ data.item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") }}
      </template>
    </b-table>
  </div>
</template>
 
<script>
export default {
  name: "balance-sheet",
  props: {
    balanceSheetData: null,
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      fields: [
        { key: "ticker", sortable: true },
        { key: "value", sortable: true },
      ],
      sortBy: "value",
      sortDesc: true,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {},
  /* eslint-enable no-console */
};
</script>
 
<style>
.list {
  text-align: left;
  max-width: 450px;
  margin: auto;
}
.center {
  margin: auto;
  width: 100%;
  padding: 10px;
}
</style>