import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./components/Dashboard.vue";
import Overview from "./components/Overview.vue";
import FinAssets from "./components/FinAssets.vue";
import Home from "./components/Home.vue";
import Register from "./components/Register.vue";
import AddTransaction from "./components/AddTransaction.vue"
import Preferences from "./components/Preferences.vue"
import Taxes from "./components/Taxes.vue"
import Custom from "./components/Custom.vue"

import store from './store.js'

Vue.use(Router);

let router = new Router({
    mode: "history",
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
            path: "/dashboard",
            name: "dashboard",
            alias: "/dashboard2",
            component: Dashboard,
            // meta: {requiresAuth: true},
            children: [
                {
                    path: "/overview",
                    name: "overview",
                    // meta: {requiresAuth: true},
                    component: Overview
                },
                {
                    path: "/fin-assets",
                    name: "fin-assets",
                    // meta: {requiresAuth: true},
                    component: FinAssets
                },
                {
                    path: "/add-custom-prices",
                    name: "add-custom-prices",
                    // meta: {requiresAuth: true},
                    component: Custom
                },
                {
                    path: "/add-transaction",
                    name: "add-transaction",
                    // meta: {requiresAuth: true},
                    component: AddTransaction
                },
                {
                    path: "/taxes",
                    name: "taxes",
                    // meta: {requiresAuth: true},
                    component: Taxes
                },
                {
                    path: "/preferences",
                    name: "preferences",
                    // meta: {requiresAuth: true},
                    component: Preferences
                },
            ]
        },
    ]
});

// ** update routes to require auth meta: {requiresAuth: true}
// router.beforeEach((to, from, next) => {
//     // let isLoggedIn = false
//     // try {
//     //     let isLoggedIn = store.getters.isLoggedIn;
//     // } catch {
//     // }

//     // if (isLoggedIn) {
//     //     if (to.path === "/login") {next("/")} // if going to login page and logged in go to home
//     //     else next() //other go to where you wanted to go
//     // } else { // if not logged in
//     //     if (to.path === "/login") {next()} // if going to /login just go next
//     //     if (to.path !== "/login") {next("/login")} // if going to somewhere else, go to login         
//     // }

//     console.log("to: ", to.path)
//     console.log("from: ", from.path)
//     next()
// });

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/') // Send them home to the login/register page
    } else {
      next()
    }
  })

export default router