<template>
  <div v-if="arrayOfTotalQuantities != null">
    <h1>Taxes page</h1>
    <br />
    <h3>Portfolio state as at {{ currentInventory.date }}</h3>
    <br />
    <label>Select tax year</label>
    <select
      class="form-control"
      id="exampleFormControlSelect1"
      v-model="taxYear"
    >
      <option
        v-for="(year, index) in range(firstTranasctionYear, currentYear)"
        :key="index"
      >
        {{ year }}
      </option>
    </select>
    <b-table
        id="transactions-table"
        :items="filterTransactionsBasedOnFY"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
        striped
      >
        <template #head(price)> Price ($) </template>
        <template #head(brokerage)> Brokerage ($) </template>
        <template #cell(price)="transaction">
          {{
            transaction.item.price
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          }}
        </template>
      </b-table>
    <br /><br /><br /><br />
    <h5 v-for="(item, index) in currentInventory.data" :key="index">
      Asset: {{ index }}
      <br />
      <br />
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>Date of parcel acquisition</b-th>
            <b-th>Quantity</b-th>
            <b-th>Cost ($)</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item2, index2) in item" :key="index2">
            <b-td>{{ item2.date }}</b-td>
            <b-td>{{ item2.quantity.toFixed() }}</b-td>
            <b-td>{{ item2.cost.toFixed(2) }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td variant="secondary" class="text-right"> Total </b-td>
            <b-td variant="secondary">
              <b>{{ arrayOfTotalQuantities[index] }}</b>
            </b-td>
            <b-td variant="secondary"> </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </h5>
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: "Taxes",
  props: {
    stockPortfolio: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      taxYear: new Date().getFullYear(),
      perPage: 1000,
      currentPage: 1,
      fields: [
        { key: "transaction_date", sortable: true },
        { key: "ticker", sortable: true },
        { key: "quantity", sortable: true },
        { key: "price", sortable: true },
        { key: "transaction_type", sortable: true },
        { key: "brokerage", sortable: true },
        { key: "currency", sortable: true },
      ],
      sortBy: "transaction_date",
      sortDesc: true,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    firstTranasctionYear() {
      // check if first date is after 30 June X year
      let first_date = new Date(
        this.stockPortfolio.transactions[0].transaction_date
      );
      console.log('firstdate', first_date)
      if (first_date.getMonth() > 5) {
        // 5 is June, all months are 0-11
        return first_date.getFullYear() + 1;
      } else {
        return first_date.getFullYear() ;
      }
    },
    currentInventory() {
      if (this.stockPortfolio != null) {
        return this.stockPortfolio.inventory[
          this.stockPortfolio.inventory.length - 1
        ];
      }
      return null;
    },
    arrayOfTotalQuantities() {
      if (this.stockPortfolio != null) {
        console.log("inside func");
        let arrayOfQuanties = {};
        console.log(this.currentInventory.data);
        for (let key in this.currentInventory.data) {
          let value = this.currentInventory.data[key];
          console.log(key, value);

          let total = value.reduce((acc, obj) => {
            return acc + obj.quantity;
          }, 0);
          arrayOfQuanties[key] = total.toFixed();
        }
        return arrayOfQuanties;
      }
      return null;
    },
    currentRangeCalc() {
      return this.range(this.firstTranasctionYear, this.currentYear);
    },
    filterTransactionsBasedOnFY() {
      let taxYear = this.taxYear;
      let transactions = Object.assign([], this.stockPortfolio.transactions);
      let currentYear = new Date(taxYear,0,1);
      let startDate = currentYear.setMonth(currentYear.getMonth()-6);

      currentYear = new Date(taxYear,0,1);
      let endDate = currentYear.setMonth(currentYear.getMonth() + 6);

      // let endDate = new Date(startDate).getYear() +1 ;
      console.log('start date, ', new Date(startDate), "endDate ", new Date(endDate))
      let filteredTransactions = transactions.filter((transaction) => {
        let currentDate = new Date(transaction.transaction_date);
        if (currentDate >= startDate && currentDate < endDate) {
          return transaction;
        }
      });
      return filteredTransactions;
    },
  },
  methods: {
    range(start, end) {
      return Array.from({ length: end - start + 1 }, (_, i) => i + start);
    },
  },
  mounted() {},
  /* eslint-enable no-console */
};
</script>


