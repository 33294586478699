<template>
  <div>
    <!-- Content section -->
    <main class="content">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12 col-sm-6 col-xxl d-flex">
            <div class="card flex-fill">
              <div class="card-body py-4">
                <div class="media">
                  <div class="media-body">
                    <h3 class="mb-2">
                      $
                      {{
                        currentPortfolioValue
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      }}
                    </h3>
                    <p class="mb-2">Current portfolio value</p>
                    <div class="mb-0">
                      <span v-if="stockPortfolio != null" class="text-muted">
                        {{ controllerIRR }}% p/a</span
                      >
                    </div>
                  </div>
                  <div class="d-inline-block ml-3">
                    <div class="stat">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-dollar-sign align-middle text-success"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path
                          d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xxl d-flex">
            <div class="card flex-fill">
              <div class="card-body py-4">
                <div class="media">
                  <div class="media-body">
                    <h3 class="mb-2">
                      Set data range, current: {{ dataRange }}
                    </h3>
                    <b-button class="m-md-1" @click="dataRange = 7"
                      >7d</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 30"
                      >30d</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 90"
                      >90d</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 180"
                      >180d</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 365"
                      >1y</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 730"
                      >2y</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 1825"
                      >5y</b-button
                    >
                    <b-button class="m-md-1" @click="dataRange = 36500"
                      >max</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xxl d-flex">
            <div class="card flex-fill">
              <div class="card-body py-4">
                <div class="media">
                  <div class="media-body">
                    <h3 class="mb-2">
                      Toggle data sources, Current: {{ currentAssetTypes }}
                    </h3>
                    <b-button class="m-md-1" @click="toggleAssetTypes('Share')"
                      >Shares</b-button
                    >
                    <b-button class="m-md-1" @click="toggleAssetTypes('Crypto')"
                      >Crypto</b-button
                    >
                    <b-button class="m-md-1" @click="toggleAssetTypes('Custom')"
                      >Custom</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 d-flex">
            <div class="card flex-fill w-100">
              <div class="card-header">
                <div class="card-actions float-end">
                  <div class="dropdown show">
                    <a
                      href="#"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                    >
                      <i
                        class="align-middle"
                        data-feather="more-horizontal"
                      ></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-end">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
                <h5 class="card-title mb-0">Historical Portfolio Value</h5>
              </div>
              <div class="card-body d-flex w-100">
                <div class="align-self-center chart chart-lg">
                  <charts
                    v-if="stockPortfolio != null"
                    :chartData="stockPortfolioWithRange"
                    :key="componentKey"
                  ></charts>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="card flex-fill w-100">
              <div class="card-header">
                <h5 class="card-title mb-0">Holdings</h5>
              </div>
              <holdings :currentPortfolioData="currentPortfolioData"></holdings>
            </div>
          </div>
        </div>
        <transactions
          v-if="stockPortfolio != null"
          :transactions="transactions"
        ></transactions>
      </div>
    </main>
    <div></div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Charts from "./Charts";
import Holdings from "./Holdings";
import Transactions from "./Transactions";

export default {
  components: { Charts, Holdings, Transactions },
  name: "fin-assets",
  props: {
    stockPortfolio: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dataRange: 36500,
      componentKey: 0,
      defaultAssetTypes: ["Share", "Crypto", "Custom"],
      currentAssetTypes: ["Share", "Crypto", "Custom"],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    balanceSheetData() {
      if (this.stockPortfolioWithRange !== null) {
        let uniqueStocks = this.stockPortfolioWithRange.all_daily_asset_values;
        let balanceSheetData = [];
        for (var ticker in uniqueStocks) {
          let value = uniqueStocks[ticker][uniqueStocks[ticker].length - 1];
          balanceSheetData.push({ ticker, value });
        }
        return balanceSheetData;
      }
      return null;
    },
    currentPortfolioData() {
      let currentPortfolioData = [];
      if (this.stockPortfolio !== null) {
        let portfolio_chain = this.stockPortfolio.portfolio_chain;
        let last_entry = portfolio_chain[portfolio_chain.length - 1];
        let keys = Object.keys(last_entry.price);
        for (let key in keys) {
          let ticker = keys[key];
          let price = last_entry.price[ticker];
          let quantity = last_entry.quantity[ticker];
          let value = price * quantity;
          currentPortfolioData.push({ ticker, quantity, price, value });
          // Any other collumns go here
        }
      }
      return currentPortfolioData;
    },
    transactions() {
      if (this.stockPortfolio !== null) {
        return this.stockPortfolio.transactions;
      }
      return null;
    },
    stockPortfolioWithRange() {
      let stockPortfolio = this.stockPortfolio;
      // set data at start
      var stockPortfolioWithRange = JSON.parse(JSON.stringify(stockPortfolio));
      stockPortfolioWithRange.all_daily_asset_values = {};

      let dailyAssetValues = JSON.parse(
        JSON.stringify(stockPortfolio.all_daily_asset_values)
      );
      let filteredAssetValues = {};

      let assetObject = this.getAssetTypeObject();

      let unique_tickers = [];

      for (let ticker in dailyAssetValues) {
        let assetType = assetObject[ticker];

        if (this.currentAssetTypes.includes(assetType)) {
          filteredAssetValues[ticker] = dailyAssetValues[ticker];

          unique_tickers.push(ticker);
        }
      }

      // Reset unique tickers
      stockPortfolioWithRange.unique_tickers = unique_tickers.sort(); // this has unsorted them

      // Filter data
      var length = 0;
      var start = 0;
      for (var key in filteredAssetValues) {
        let array = stockPortfolio.all_daily_asset_values[key];
        length = array.length;
        start = Math.max(length - this.dataRange, 0);
        stockPortfolioWithRange.all_daily_asset_values[
          key
        ] = stockPortfolio.all_daily_asset_values[key].slice(start, length);
      }

      length = stockPortfolio.all_dates.length;
      start = Math.max(length - this.dataRange, 0);
      stockPortfolioWithRange.all_dates = stockPortfolio.all_dates.slice(
        start,
        length
      );

      length = stockPortfolio.all_dates.length;
      start = Math.max(length - this.dataRange, 0);
      stockPortfolioWithRange.portfolio_chain = stockPortfolio.portfolio_chain.slice(
        start,
        length
      );
      // this.componentKey += 1;

      return stockPortfolioWithRange;
    },
    controllerIRR() {
      let end_date = new Date(); // Current date
      let max_range = Math.min(
        this.dataRange,
        this.stockPortfolio.all_dates.length
      );
      let start_index = end_date - max_range * (1000 * 3600 * 24); // Number of days from today
      let start_date = new Date(start_index); // Date of index

      // Filter transactions based on date range
      let transactions = this.filterTransactionsBasedOnDate(
        start_date,
        this.stockPortfolio.transactions
      );

      // array of date indexes
      let array_of_dates = transactions.map((transaction) => {
        let current_date = new Date(transaction.transaction_date);
        let diff = (current_date - start_date) / (1000 * 3600 * 24);
        return diff;
      });

      // array of cash flows
      let array_of_cashflows = transactions.map((transaction) => {
        if (transaction.transaction_type == "BUY") {
          return transaction.price * -transaction.quantity;
        } else if (transaction.transaction_type == "SELL") {
          return transaction.price * transaction.quantity;
        }
      });

      // get amount of days from end
      let days_offset = Math.ceil((end_date - start_date) / (1000 * 3600 * 24));

      // Add opening value of portfolio if transactions have been filtered
      if (transactions.length != this.stockPortfolio.transactions.length) {
        // If transactions have been filtered
        let value_at_start = 0.0;

        for (let item in this.stockPortfolio.all_daily_asset_values) {
          // Add portfolio value on preceding day
          let array = this.stockPortfolio.all_daily_asset_values[item];
          value_at_start -= array[array.length - days_offset - 1];
        }

        array_of_dates.unshift(0); // Add end date to start
        array_of_cashflows.unshift(value_at_start); // add starting portfolio value
      }

      // Add end portfolio value and date index to end
      array_of_dates.push((end_date - start_date) / (1000 * 3600 * 24));
      array_of_cashflows.push(this.currentPortfolioValue);

      // Calculate and return IRR
      let IRRraw = this.internalRateOfReturn(
        array_of_cashflows,
        array_of_dates,
        -0.99
      );

      return this.roundToTwo(IRRraw * 100);
    },
    currentPortfolioValue() {
      // get current value of portfolio ** index into portfoliovalue object based on date diff and sum all values in various arrays
      if (this.stockPortfolio != null) {
        let current_value_of_portfolio = 0.0;

        for (let item in this.stockPortfolio.all_daily_asset_values) {
          let array = this.stockPortfolio.all_daily_asset_values[item];
          current_value_of_portfolio += array[array.length - 1];
        }
        return current_value_of_portfolio;
      }
      return null;
    },
  },
  methods: {
    filterTransactionsBasedOnDate(start_date, transactions) {
      let filteredTransactions = transactions.filter((transaction) => {
        let current_date = new Date(transaction.transaction_date);
        if (current_date > start_date) {
          return transaction;
        }
      });
      return filteredTransactions;
    },
    internalRateOfReturn(values, dates, guess) {
      // Credits: algorithm inspired by https://gist.github.com/ghalimi/4591338

      // Calculates the resulting amount
      var irrResult = function (values, dates, rate) {
        var r = rate + 1;
        var result = values[0];
        for (var i = 1; i < values.length; i++) {
          result += values[i] / Math.pow(r, dates[i] / 365);
        }
        return result;
      };

      // Calculates the first derivation
      var irrResultDeriv = function (values, dates, rate) {
        var r = rate + 1;
        var result = 0;
        for (var i = 1; i < values.length; i++) {
          var frac = dates[i] / 365;
          result -= (frac * values[i]) / Math.pow(r, frac + 1);
        }
        return result;
      };

      // Initialize dates and check that values contains at least one positive value and one negative value
      // var dates = [];
      var positive = false;
      var negative = false;
      for (var i = 0; i < values.length; i++) {
        // dates[i] = i === 0 ? 0 : dates[i - 1] + 365;
        if (values[i] > 0) positive = true;
        if (values[i] < 0) negative = true;
      }

      // Return error if values does not contain at least one positive value and one negative value
      if (!positive || !negative) return "#NUM!";

      // Initialize guess and resultRate
      guess = typeof guess === "undefined" ? 0.1 : guess;
      var resultRate = guess;

      // Set maximum epsilon for end of iteration
      var epsMax = 1e-10;

      // Set maximum number of iterations
      var iterMax = 50;

      // Implement Newton's method
      var newRate, epsRate, resultValue;
      var iteration = 0;
      var contLoop = true;
      do {
        resultValue = irrResult(values, dates, resultRate);
        newRate =
          resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
        epsRate = Math.abs(newRate - resultRate);
        resultRate = newRate;
        contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax;
      } while (contLoop && ++iteration < iterMax);

      if (contLoop) return "#NUM!";

      // Return internal rate of return
      return resultRate;
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    getAssetTypeObject() {
      let tickers = this.stockPortfolio.unique_tickers;
      let lastDay = this.stockPortfolio.portfolio_chain[
        this.stockPortfolio.portfolio_chain.length - 1
      ];
      let tickerToAssetType = {};
      // console.log("lastDay", lastDay)
      for (let ticker in tickers) {
        let ticker2 = tickers[ticker];
        // console.log("ticker here2", ticker2);
        tickerToAssetType[ticker2] = lastDay.security_type[ticker2];
      }
      return tickerToAssetType;
    },
    toggleAssetTypes(AssetType) {
      this.currentAssetTypes.includes(AssetType)
        ? (this.currentAssetTypes = this.currentAssetTypes.filter(
            (e) => e !== AssetType
          ))
        : this.currentAssetTypes.push(AssetType);
    },
  },
  mounted() {},
  watch: {
    dataRange() {
      this.componentKey += 1;
    },
    currentAssetTypes() {
      this.componentKey += 1;
    },
  },
};
/* eslint-enable no-console */
</script>
 
<style>
</style>


