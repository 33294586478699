<template>
  <div class="card flex-fill">
    <div class="card-header">
      <h5 class="card-title mb-0">Transactions</h5>
    </div>
    <div
      id="transactions-section"
      class="dataTables_wrapper dt-bootstrap4 no-footer"
    >
      <b-table
        id="transactions-table"
        :items="transactions"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
        striped
      >
        <template #head(price)> Price ($) </template>
        <template #head(brokerage)> Brokerage ($) </template>
        <template #cell(price)="transaction">
          {{
            transaction.item.price
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div
            class="dataTables_info"
            id="datatables-dashboard-projects_info"
            role="status"
            aria-live="polite"
          >
            Showing {{ 1 + (currentPage - 1) * perPage }} to
            {{ Math.min(currentPage * perPage, rows) }} of {{ rows }} entries
          </div>
        </div>
        <div class="col-sm-12 col-md-7">
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="datatables-dashboard-projects_paginate"
          >
            <ul class="pagination" style="justify-content: flex-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                aria-controls="transactions-table"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
      <p class="mt-3"></p>
    </div>
  </div>
</template> 
<script>
export default {
  name: "transactions",
  props: {
    transactions: null,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "transaction_date", sortable: true },
        { key: "security_id", sortable: true },
        { key: "security_type", sortable: true },
        { key: "quantity", sortable: true },
        { key: "price", sortable: true },
        { key: "transaction_type", sortable: true },
        { key: "brokerage", sortable: true },
        { key: "currency", sortable: true },
      ],
      sortBy: "transaction_date",
      sortDesc: true,
    };
  },
  watch: {},
  computed: {
    rows() {
      // if (!this.transactions === null) {
      //   return this.transactions.length;
      // }
      // return 0
      return this.transactions.length;
    },
  },
  methods: {},
  mounted() {},
  /* eslint-enable no-console */
};
</script>
 
<style>
</style>